<template>
    <v-dialog v-model="showDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title class="headline">
          Terms and Conditions
          <!-- <v-btn icon @click="closeDialog" class="close-icon">
            <v-icon color="red">close</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-card-text class="scrollable-content">
        <div class="terms-content" v-html="content"></div>
      </v-card-text>
        <v-card-actions>
          <v-btn @click="createAndAcceptTerms" color="green">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      showDialog: Boolean,
      content: String,
      version: String,
    },
    data() {
    return {
      loading: true,
      error: null,
    };
  },

    methods: {
      acceptTerms() {
        this.$emit('accept-terms', this.version);
      },
      closeDialog() {
        this.$emit('close', this.version);
      },

      async fetchTermsAndConditions() {
      try {
        const response = await this.$API.getTerms();
          // organisationId: this.organisationId;

        if (response && response.content) {
          this.content = response.content;
          this.loading = false;
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        this.error = 'Failed to load terms and conditions.';
        this.loading = false;
      }
    },

    async createAndAcceptTerms() {
      try {
        await this.$API.createTermsAndConditions({
          userId: this.$store.state.user.id,
          organisationId: this.$store.state.currentOrg.id,
        });
        this.acceptTerms();
      } catch (error) {
        console.error('Error creating and accepting terms:', error);
        this.error = 'Failed to accept terms and conditions.';
      }
    },

    }
  };
  </script>
  
  
  <style scoped>

.scrollable-content {
  max-height: 500px;
  overflow-y: auto;
}
  .terms-content {
    max-height: 500px;
    overflow-y: auto;
    padding: 16px;
    font-size: 14px;
    line-height: 1.6;
  }
  
  .v-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
  }
  
  .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .v-btn {
    margin: 0;
    padding: 0;
  }
  
  .v-btn .v-icon {
    font-size: 24px;
  }
  </style>
  
  